import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

const EmailInput = ({ name, form, required, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  const validateEmail = (_, value) => {
    if (value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      return Promise.reject('Мэйл хаягаа зөв оруулна уу!');
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      label={props.label}
      style={{ width: '100%', marginBottom: 0 }}
      name={name}
      rules={[
        { required: required, message: 'Мэйл хаягаа оруулна уу !' },
        { validator: validateEmail },
      ]}
      {...props}
    >
      <Input placeholder='Мэйл хаягаа оруулна уу' />
    </Form.Item>
  );
};

export default EmailInput;
