import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Loader = () => {
  return (
    <>
      <Spinner>
        <Spin tip='' size='large'></Spin>
      </Spinner>
      <Shadow />
    </>
  );
};

const Shadow = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000000;
`;
const Spinner = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1100000;
`;

export default Loader;
